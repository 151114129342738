import arrayMutators from 'final-form-arrays';
import { func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
// These relative imports need to point to correct directories
import { useDispatch } from 'react-redux';
import { Button, Form } from '../../components';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { onGetInterestList } from '../../util/api';
import {
  FormattedMessage,
  intlShape
} from '../../util/reactIntl';
import css from "./InterestsForm.module.css";
import IconCollection from '../IconCollection/IconCollection';

const InterestsForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          updated,
          formId,
          updateError,
          updateInProgress,
          values,
          form,
          successToaster,
          errorToaster,
          currentUser,
          handleInterestModalClose,
        } = formRenderProps;

        const [interests, setInterests] = useState([]);
        const [selectedInterests, setSelectedInterests] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        const dispatch = useDispatch();

        const errorMessage = updateError ? (
          <p className={css.error}>
            <FormattedMessage id="ContactUsForm.updateFailed" />
          </p>
        ) : null;

        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress || isLoading;
        const submitDisabled = invalid || disabled || submitInProgress || !(selectedInterests?.length > 0 && selectedInterests?.length <= 5);

        useEffect(() => {
          const fetchInterest = async () => {
            const response = await onGetInterestList();
            setInterests(response || [])
          }

          fetchInterest();
        }, [])

        return (
          <Form onSubmit={(e) => {
            e.preventDefault();
            setIsLoading(true);
            dispatch(updateProfile({ publicData: { userInterests: selectedInterests } }))
              .then(() => {
                setIsLoading(false);
                handleInterestModalClose();
              })
          }}
            className={css.mainForm}
          >
            <div className={css.verifyBox}>
              <h3 className={css.verifyTitle}>
                <FormattedMessage id="InterestsForm.heading" />
              </h3>
              <p className={css.verifySubTitle}>
                <FormattedMessage id="InterestsForm.subHeading" />
              </p>
              {interests?.length > 0 ? (
                interests.map(interest => {
                  const { id, label, value } = interest || {};
                  const isChecked = selectedInterests?.includes(id);
                  return (
                    <div className={css.itemCheckbox} onClick={() => setSelectedInterests(prevState =>
                      prevState.includes(id)
                        ? prevState.filter(int => int !== id)
                        : [...prevState, id]
                    )}
                    >
                      <span>
                        {isChecked ?
                          <IconCollection name="interest_checked" />
                          :
                          <IconCollection name="interest_notChecked" />

                        }
                      </span>
                      <FormattedMessage id={`category.${id}`} />
                    </div>
                  )
                })
              ) : null}

              {selectedInterests?.length > 5 ? (
                <FormattedMessage id="InterestsForm.selectMaxFive" />
              ) : null}

              <Button
                className={css.buttonSubmit}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                <FormattedMessage id="InterestsForm.submitButtonLabel" />
              </Button>
            </div>
          </Form>
        );
      }}
    />
  )
}

InterestsForm.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

InterestsForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default InterestsForm;
